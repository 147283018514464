
import SmartBarcodeIcon from '@/components/svg/SmartBarcodeIcon.vue'
import SmartBarcodeLogo from '@/components/svg/SmartBarcodeLogo.vue'
import { BARCODE_IMPORT_LAYOUT_PDF_EXPORT } from '@/utils/constants'
import { exportJsPDF, exportPDFByBuffer } from '@/utils/exportPdf'
import { displayMomentAtParam } from '@/utils/timeUtils'
import { IBarcodeList, ILayoutPdf, IOriginTimeTable, IProject, ITimeTableSchedule } from 'smartbarcode-web-core/src/utils/types/index'
import { jsPDFOptions } from 'jspdf'
import moment from 'moment-timezone'
import { Options, Vue } from 'vue-class-component'
import { InjectReactive, Prop, Watch } from 'vue-property-decorator'

export interface ILayoutItemPdf {
  column: number
  row: number
}

@Options({
  components: {
    SmartBarcodeLogo,
    SmartBarcodeIcon,
  },
  emits: ['update:exportPDFDone'],
  name: 'BarcodePDFLayout',
})
export default class BarcodePDFLayout extends Vue {
  @Prop({ type: Object }) readonly project!: IProject
  @Prop({ type: Array }) readonly barcodes!: IBarcodeList[]
  @Prop({ type: Number }) readonly layout!: number
  @Prop({ type: Array }) readonly showLabel!: Array<string>
  @Prop({ type: String }) readonly color!: string
  @InjectReactive() projectVersion!: number

  jsPDF = {
    unit: 'pt',
    format: [595, 850],
    orientation: 'p',
  } as jsPDFOptions

  logoId = 'pdf-smartbarcode-logo'

  pagePerGroup = 30

  layoutItem = {} as ILayoutItemPdf
  itemCss = {} as ILayoutPdf
  barcodeImages = {} as Record<string, string>

  // canvas - page - row
  barcodeExportData = [] as Array<Array<Array<Array<IBarcodeList>>>>

  localLoading = true

  get timetableSchedules(): ITimeTableSchedule[] {
    return this.$store.state.timetable.timetableSchedules || []
  }

  get timetable(): IOriginTimeTable {
    return this.$store.state.timetable.timetable
  }

  get exportFilename() {
    return `barcode-group-${displayMomentAtParam(moment())}.pdf`
  }

  @Watch('localLoading')
  onExportReady() {
    this.exportPDF()
  }

  getPageNumber(index: string) {
    return parseInt(index, 10) + 1
  }

  async makeBarcodeImages() {
    switch (this.layout) {
      case Number(BARCODE_IMPORT_LAYOUT_PDF_EXPORT.a22):
      this.itemCss = {
          backgroundColor: this.color,
          backgroundFrameWidth: 297,
          backgroundFrameHeight: 420,
          width: 80,
          barcodeBeginX: 112,
          barcodeBeginY: 87,
          marginItemX: 214,
          marginItemY: 338,
          frameBeginX: 0,
          frameBeginY: 0,
          marginFrameX: 1,
          marginFrameY: 1,
          headerTextbeginX: 0,
          headerTextbeginY: 0,
          headerTextSize: 0,
          logoWidth: 0,
          logoHeight: 0,
          logoBeginY: 0,
          logoRightX: 0,
          contentTextSize: 18,
          contentTextMarginTop: 65,
          isSupportLogoAndTitle: false,
          isSupportDiamondFrame: true,
        }

        this.layoutItem = { column: 2, row: 2 }
        break
      case Number(BARCODE_IMPORT_LAYOUT_PDF_EXPORT.a34):
        this.itemCss = {
          backgroundColor: this.color,
          backgroundFrameWidth: 180,
          backgroundFrameHeight: 180,
          width: 90,
          barcodeBeginX: 67,
          barcodeBeginY: 76,
          marginItemX: 94,
          marginItemY: 93,
          frameBeginX: 23,
          frameBeginY: 54,
          marginFrameX: 3,
          marginFrameY: 3,
          headerTextbeginX: 56,
          headerTextbeginY: 32,
          headerTextSize: 12,
          logoWidth: 114,
          logoHeight: 20,
          logoBeginY: 21,
          logoRightX: 27,
          contentTextSize: 10,
          contentTextMarginTop: 22,
          isSupportLogoAndTitle: true,
        }

        this.layoutItem = {
          column: 3,
          row: 4,
        }
        break
      case Number(BARCODE_IMPORT_LAYOUT_PDF_EXPORT.a446):
        this.itemCss = {
          backgroundColor: this.color,
          backgroundFrameWidth: 120,
          backgroundFrameHeight: 120,
          width: 64,
          frameBeginX: 49,
          frameBeginY: 49,
          marginFrameX: 3,
          marginFrameY: 3,
          marginItemX: 60,
          marginItemY: 60,
          headerTextbeginX: 60,
          headerTextbeginY: 32,
          headerTextSize: 10,
          barcodeBeginX: 79,
          barcodeBeginY: 72,
          logoWidth: 110,
          logoHeight: 18.87,
          logoBeginY: 23,
          logoRightX: 30,
          contentTextSize: 5,
          contentTextMarginTop: 10,
          isSupportLogoAndTitle: true,
        }
        this.layoutItem = {
          column: 4,
          row: 6,
        }
        break
      case Number(BARCODE_IMPORT_LAYOUT_PDF_EXPORT.a4710):
        this.itemCss = {
          backgroundColor: this.color,
          backgroundFrameWidth: 65,
          backgroundFrameHeight: 65,
          width: 35,
          frameBeginX: 61,
          frameBeginY: 82,
          marginFrameX: 3,
          marginFrameY: 3,
          marginItemX: 33,
          marginItemY: 33,
          headerTextbeginX: 58,
          headerTextbeginY: 32,
          headerTextSize: 10,
          barcodeBeginX: 77,
          barcodeBeginY: 92,
          logoWidth: 110,
          logoHeight: 18.87,
          logoBeginY: 23,
          logoRightX: 30,
          contentTextSize: 5,
          contentTextMarginTop: 10,
          isSupportLogoAndTitle: true,
        }
        this.layoutItem = {
          column: 7,
          row: 10,
        }
        break
      case Number(BARCODE_IMPORT_LAYOUT_PDF_EXPORT.a458):
      default:
        this.itemCss = {
          backgroundColor: this.color,
          backgroundFrameWidth: 94,
          backgroundFrameHeight: 94,
          width: 55,
          frameBeginX: 58,
          frameBeginY: 39,
          marginFrameX: 3,
          marginFrameY: 3,
          marginItemX: 41.5,
          marginItemY: 41.5,
          headerTextbeginX: 54,
          headerTextbeginY: 22,
          headerTextSize: 12,
          barcodeBeginX: 78,
          barcodeBeginY: 50,
          logoWidth: 92,
          logoHeight: 15.78,
          logoBeginY: 20,
          logoRightX: 29,
          contentTextSize: 5,
          contentTextMarginTop: 10,
          isSupportLogoAndTitle: true,
        }
        this.layoutItem = {
          column: 5,
          row: 8,
        }
        break
    }
    this.formatBarcodeExportData()
  }

  async formatBarcodeExportData() {
    const barcodeExportData = [] as Array<Array<Array<Array<IBarcodeList>>>>
    let barcodeExportPerGroup = []
    let barcodeExportPerPage = []
    let barcodeExportPerRow = [] as IBarcodeList[]

    const numberItemLayout = this.layoutItem.column * this.layoutItem.row
    const itemPerGroup = this.pagePerGroup * numberItemLayout

    const numberBarcode = this.barcodes.length
    for (let skip = 0; skip < numberBarcode; skip++) {
      const item = this.barcodes[skip]
      item.qrUrl = this.getUrlQrcode(this.project.code as string, item.id)

      barcodeExportPerRow.push(item)

      if ((skip !== 0 && (skip + 1) % this.layoutItem.column === 0) || skip === numberBarcode - 1) {
        barcodeExportPerPage.push(barcodeExportPerRow)
        barcodeExportPerRow = []
      }

      if ((skip !== 0 && (skip + 1) % numberItemLayout === 0) || skip === numberBarcode - 1) {
        barcodeExportPerGroup.push(barcodeExportPerPage)
        barcodeExportPerPage = []
      }

      if ((skip !== 0 && (skip + 1) % itemPerGroup === 0) || skip === numberBarcode - 1) {
        barcodeExportData.push(barcodeExportPerGroup)
        barcodeExportPerGroup = []
      }
    }

    this.barcodeExportData = barcodeExportData
  }

  getUrlQrcode(projectCode: string, barcodeId: string) {
    const appHost = process.env.VUE_APP_MOBILE_BASE_URL
    const url = `${appHost}/${projectCode}/qrcode/${barcodeId}`

    return url
  }

  async exportPDF() {
    const arrBuffers: ArrayBuffer[] = []
    const numberBarcode = this.barcodeExportData.length
    const headerText = `${this.project.name}${this.project.code ? ` | ${this.project.code}` : ''}${
      this.projectVersion && this.projectVersion !== 0 ? ` | ver.${this.projectVersion}` : ''
    }`
    for (let i = 0; i < numberBarcode; i++) {
      const startPage = i * this.pagePerGroup
      const arrBuffer = await exportJsPDF(
        this.jsPDF,
        this.barcodeExportData[i],
        headerText,
        this.itemCss,
        this.logoId,
        this.layout,
        startPage,
        this.showLabel
      )

      arrBuffers.push(arrBuffer)
    }

    await exportPDFByBuffer(arrBuffers, this.exportFilename)

    this.$emit('update:exportPDFDone')
  }

  async mounted() {
    await this.makeBarcodeImages()

    this.localLoading = false
  }
}
