import { jsPDF, jsPDFOptions } from 'jspdf'
import cloneDeep from 'lodash/cloneDeep'
import { PDFDocument } from 'pdf-lib'
import QRious from 'qrious'
import { FIELD_TYPE } from 'smartbarcode-web-core/src/utils/constants'
import { isEmpty } from 'smartbarcode-web-core/src/utils/typeChecker'
import { IBarcodeCustomField, IBarcodeList, ILayoutPdf } from 'smartbarcode-web-core/src/utils/types/index'
import {
  BARCODE_IMPORT_CUSTOM_FIELD_KEY,
  BARCODE_IMPORT_LAYOUT_PDF_EXPORT,
  BARCODE_IMPORT_PDF_LABELS,
  MAX_CHARACTERS_SUPPORT_EXPORT_BARCODE,
} from './constants'

// Global variable
let _layout = -1

function formatValueByKey(key: string, value: string | string[]) {
  if (_layout === BARCODE_IMPORT_LAYOUT_PDF_EXPORT.a22 && Array.isArray(value)) return value.join(', ')

  switch (key) {
    case BARCODE_IMPORT_PDF_LABELS.trackingNumber:
      return `TN: ${value}`
    case BARCODE_IMPORT_PDF_LABELS.externalId:
      return `EX ID: ${value}`
    default:
      return Array.isArray(value) ? value.join(', ') : value
  }
}

function formatValueCustomField(data: IBarcodeCustomField) {
  switch (data.fieldType) {
    case FIELD_TYPE.TEXT:
      return data.text || ''
    case FIELD_TYPE.NUMBER:
      return String(data?.number ?? '')
    case FIELD_TYPE.SINGLE_SELECT:
      return data?.singleSelect?.value || ''
    case FIELD_TYPE.MULTI_SELECT:
      return data.multiSelect ? data.multiSelect.map((item) => item.value).join(', ') : ''
    case FIELD_TYPE.DATE:
      return data.date || ''
    default:
      return data.text || ''
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getValueByKeyPath(keyPath: string[], object: any, isCustomField: boolean): string {
  const key = keyPath?.[0]
  if (!key || !object?.[key]) return ''
  if (keyPath.length <= 1) {
    return isCustomField ? formatValueCustomField(object[key]) : formatValueByKey(key, object[key].toString())
  } else {
    keyPath.shift()
    return getValueByKeyPath(keyPath, object[key], isCustomField)
  }
}

function getMaxLength(ctx: CanvasRenderingContext2D, value: string, width: number, isFirst: boolean): string {
  const checkValue = isFirst ? value : `${value}...`
  const textWidth = Math.round(ctx.measureText(checkValue).width)

  if (textWidth > width) {
    let endIndex = value.length - 1
    const maxCharacters = {
      [BARCODE_IMPORT_LAYOUT_PDF_EXPORT.a22]: MAX_CHARACTERS_SUPPORT_EXPORT_BARCODE.a22,
      [BARCODE_IMPORT_LAYOUT_PDF_EXPORT.a34]: MAX_CHARACTERS_SUPPORT_EXPORT_BARCODE.a34,
      [BARCODE_IMPORT_LAYOUT_PDF_EXPORT.a446]: MAX_CHARACTERS_SUPPORT_EXPORT_BARCODE.a446,
      [BARCODE_IMPORT_LAYOUT_PDF_EXPORT.a458]: MAX_CHARACTERS_SUPPORT_EXPORT_BARCODE.a458,
      [BARCODE_IMPORT_LAYOUT_PDF_EXPORT.a4710]: MAX_CHARACTERS_SUPPORT_EXPORT_BARCODE.a4710,
    }

    endIndex = value.length - 1 > maxCharacters[_layout] ? maxCharacters[_layout] : endIndex

    value = value.substring(0, endIndex)
    return getMaxLength(ctx, value, width, false)
  } else {
    return checkValue
  }
}

function drawDiamond(ctx: CanvasRenderingContext2D, x: number, y: number, width: number, height: number) {
  ctx.save()
  ctx.beginPath()
  ctx.moveTo(x, y)
  ctx.lineTo(x - width / 2, y + height / 2)
  ctx.lineTo(x, y + height)
  ctx.lineTo(x + width / 2, y + height / 2)
  ctx.closePath()
  ctx.stroke()
  ctx.restore()
}

async function renderFrame(
  id: string,
  width: number,
  height: number,
  color: string,
  showTexts: string[],
  textWidth: number,
  barcodeBeginY: number,
  barcodeWidth: number,
  contentTextMarginTop: number,
  fontSize: number,
  isSupportDiamondFrame?: boolean
) {
  const ratio = 2
  const canvasWidth = width * ratio
  const canvasHeight = height * ratio
  const canvasTextWidth = textWidth * ratio

  const idCanvas = `frame-${id}`
  const canvaElements = document.createElement('canvas') as HTMLCanvasElement
  canvaElements.id = idCanvas
  canvaElements.width = canvasWidth
  canvaElements.height = canvasHeight
  document.body.appendChild(canvaElements)
  const canvas = document.getElementById(idCanvas) as HTMLCanvasElement

  let imageData = ''
  if (canvas) {
    const ctx = canvas.getContext('2d') as CanvasRenderingContext2D
    ctx.fillStyle = color
    ctx.fillRect(0, 0, canvasWidth, canvasHeight)
    if (isSupportDiamondFrame) {
      drawDiamond(ctx, canvasWidth / 2, barcodeBeginY + (barcodeBeginY - barcodeWidth), 420, 315)
    }

    ctx.fillStyle = 'black'
    ctx.font = `${fontSize * ratio}px Arial`
    ctx.textAlign = 'center'
    showTexts.forEach((text, index) => {
      const positionYText = contentTextMarginTop * ratio + index * (13 * ratio)
      const textFormat = getMaxLength(ctx, text, canvasTextWidth, true)
      ctx.fillText(textFormat, canvasWidth / 2, positionYText + index * fontSize)
    })

    imageData = await canvas.toDataURL('image/jpeg,1.0')
    canvas.remove()
  }

  return imageData
}

async function renderQrcode(value: string, color: string) {
  const qr = new QRious()
  qr.set({
    background: color,
    level: 'L',
    size: 200,
    value,
  })
  return await qr.toDataURL('image/jpeg')
}

async function renderTitle(width: number, height: number, text: string, fontSize: number) {
  const ratio = 4
  const canvasWidth = width * ratio
  const canvasHeight = height * ratio

  const idCanvas = 'canvas-title'
  const canvaElements = document.createElement('canvas') as HTMLCanvasElement
  canvaElements.id = idCanvas
  canvaElements.width = canvasWidth
  canvaElements.height = canvasHeight
  document.body.appendChild(canvaElements)
  const canvas = document.getElementById(idCanvas) as HTMLCanvasElement

  let imageData = ''
  if (canvas) {
    const ctx = canvas.getContext('2d') as CanvasRenderingContext2D
    ctx.fillStyle = 'black'
    ctx.font = `${fontSize * ratio}px Arial`
    ctx.textAlign = 'left'
    ctx.fillText(text, 10, canvasHeight - fontSize)

    imageData = await canvas.toDataURL('image/jpeg,1.0')
    canvas.remove()
  }

  return imageData
}

export async function exportJsPDF(
  jspdfOptions: jsPDFOptions,
  barcodeExport: Array<Array<Array<IBarcodeList>>>,
  headerText: string,
  itemCss: ILayoutPdf,
  logoId: string,
  layout: number,
  startPage: number,
  showLabel: Array<string>
) {
  _layout = layout
  // eslint-disable-next-line new-cap
  const doc = new jsPDF(jspdfOptions)

  const headerElement = document.getElementById(logoId) as HTMLImageElement

  const beginLogoX = Number(jspdfOptions?.format?.[0] ?? 0) - itemCss.logoWidth - itemCss.logoRightX

  const numberPage = barcodeExport.length

  const arrKeyPath = [] as Array<Array<string>>
  showLabel.forEach((item) => {
    if (!isEmpty(item)) {
      arrKeyPath.push(item.split('.'))
    }
  })

  let titleWidth = 0
  let titleHeight = 0
  let pdfTitle = ''
  if (itemCss.isSupportLogoAndTitle) {
    titleWidth = 300
    titleHeight = itemCss.headerTextSize
    pdfTitle = await renderTitle(titleWidth, titleHeight, headerText, itemCss.headerTextSize)
  }

  for (let i = 0; i < barcodeExport.length; i++) {
    const barcodePerPage = barcodeExport[i]
    if (itemCss.isSupportLogoAndTitle) {
      doc.setFontSize(itemCss.headerTextSize)
      doc.addImage(
        pdfTitle,
        'JPEG',
        itemCss.headerTextbeginX,
        itemCss.headerTextbeginY,
        titleWidth,
        titleHeight,
        '',
        'FAST'
      )
      doc.addImage(headerElement, 'JPEG', beginLogoX, itemCss.logoBeginY, itemCss.logoWidth, itemCss.logoHeight)
    }

    await Promise.all(
      barcodePerPage.map(async (row, k) => {
        await Promise.all(
          row.map(async (element, l) => {
            const showTexts = [] as string[]
            cloneDeep(arrKeyPath).forEach((keyPath) => {
              const elementValue = getValueByKeyPath(
                keyPath,
                element.activationData,
                keyPath[0] === BARCODE_IMPORT_CUSTOM_FIELD_KEY
              )
              showTexts.push(elementValue)
            })

            let contentTextMarginTop = itemCss.contentTextMarginTop
            let barcodeBeginY = itemCss.barcodeBeginY
            if (layout === BARCODE_IMPORT_LAYOUT_PDF_EXPORT.a34) {
              switch (showTexts.length) {
                case 0:
                case 1:
                  contentTextMarginTop = 22
                  barcodeBeginY = 89
                  break
                case 2:
                  contentTextMarginTop = 20
                  barcodeBeginY = 86
                  break
                case 3:
                  contentTextMarginTop = 16
                  barcodeBeginY = 80
                  break
              }
            }

            const framePositionX = itemCss.frameBeginX + l * (itemCss.backgroundFrameWidth + itemCss.marginFrameX)
            const framePositionY = itemCss.frameBeginY + k * (itemCss.backgroundFrameHeight + itemCss.marginFrameY)

            const positionY = barcodeBeginY + k * (itemCss.width + itemCss.marginItemY)
            const positionX = itemCss.barcodeBeginX + l * (itemCss.width + itemCss.marginItemX)

            const imageTextWidth = itemCss.width + itemCss.marginItemX - 20

            const positionTextY = positionY - framePositionY + itemCss.width + contentTextMarginTop

            const [myFrame, canvasElement] = await Promise.all([
              renderFrame(
                element.id,
                itemCss.backgroundFrameWidth,
                itemCss.backgroundFrameHeight,
                itemCss.backgroundColor,
                showTexts,
                imageTextWidth,
                itemCss.barcodeBeginY,
                itemCss.width,
                positionTextY,
                itemCss.contentTextSize,
                itemCss.isSupportDiamondFrame
              ),
              renderQrcode(element.qrUrl ?? '', itemCss.backgroundColor),
            ])

            doc.addImage(
              myFrame,
              'JPEG',
              framePositionX,
              framePositionY,
              itemCss.backgroundFrameWidth,
              itemCss.backgroundFrameHeight,
              '',
              'FAST'
            )

            doc.addImage(canvasElement, 'JPEG', positionX, positionY, itemCss.width, itemCss.width, '', 'FAST')
          })
        )
        const numberPerRow = row.length
        for (let l = 0; l < numberPerRow; l++) {}
      })
    )

    const positionPageNumberX = Number(jspdfOptions?.format?.[0]) / 2
    const positionPageNumberY = Number(jspdfOptions?.format?.[1]) - 20
    const pageNumber = String(startPage + i + 1)
    doc.text(pageNumber, positionPageNumberX, positionPageNumberY, { align: 'center' })

    if (i < numberPage - 1) {
      doc.addPage()
    }
  }

  // Reset the value of layout
  layout = -1
  return doc.output('arraybuffer')
}

export function saveCSVText(blob: Blob, fileName: string) {
  const link = document.createElement('a')
  link.href = URL.createObjectURL(blob)
  link.download = `${fileName}.csv`
  link.click()
  URL.revokeObjectURL(link.href)
}

export function saveByteArray(reportName: string, byte: Uint8Array) {
  const blob = new Blob([byte], { type: 'application/pdf' })
  const link = document.createElement('a')
  link.href = window.URL.createObjectURL(blob)
  const fileName = reportName
  link.download = fileName
  link.click()
}

export async function exportPDFByBuffer(arrBuffers: ArrayBuffer[], filename: string) {
  const newPdf = await PDFDocument.create()
  for (const arrBuffer of arrBuffers) {
    const pdfDoc = await PDFDocument.load(arrBuffer)
    const copiedPages = await newPdf.copyPages(pdfDoc, pdfDoc.getPageIndices())

    copiedPages.forEach((page) => {
      newPdf.addPage(page)
    })
  }

  const pdfBytes = await newPdf.save()
  saveByteArray(filename, pdfBytes)
}
