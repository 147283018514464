<template>
  <SvgBase iconName="IconDownload" :height="14" :width="21">
    <path d="M1 6V13H20V6" :stroke="color" stroke-width="1.5" />
    <path
      d="M15 5.9375L13.731 4.79187L11.4 6.88813L11.4 3.14722e-07L9.6 4.72083e-07L9.6 6.88813L7.269 4.79188L6 5.9375L10.5 10L15 5.9375Z"
      :fill="color"
    />
  </SvgBase>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import SvgBase from './SvgBase.vue'

@Options({
  components: {
    SvgBase,
  },
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
})
export default class IconDownload extends Vue {}
</script>
