<template>
  <el-popover v-model:visible="isShowPopover" :placement="placement" :width="width" trigger="click">
    <div class="flex flex-wrap justify-center">
      <span @click="selectColor(color)" v-for="color in colorArray" :key="color"
        ><div class="hover-animation" :style="`transition: transform 100ms ease 0s`">
          <span
            ><div
              class="rounded-full w-8 h-8 cursor-pointer m-2"
              :title="color"
              :style="`
                background: ${color};
                transition: box-shadow 100ms ease 0s;
                border: ${color === '#FFFFFF' ? '1px solid #979797;' : '0px'}`"
            ></div
          ></span></div
      ></span>
    </div>

    <template #reference>
      <div
        @click="isShowPopover = true"
        class="selected-color cursor-pointer"
        :style="`background-color: ${_selectedVal}`"
      ></div>
    </template>
  </el-popover>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import { Prop, PropSync } from 'vue-property-decorator'

@Options({
  emits: ['update:selectedVal'],
  name: 'ColorPicker',
})
export default class ColorPicker extends Vue {
  @Prop({ type: Array }) readonly colorArray!: string[]
  @Prop({ type: String }) readonly placement = 'top-start'
  @Prop({ type: String }) readonly width?: string = '320px'
  @PropSync('selectedVal', { type: String }) _selectedVal!: string
  isShowPopover = false

  selectColor(color: string) {
    this._selectedVal = color
    this.isShowPopover = false
  }
}
</script>
<style lang="scss" scoped>
.hover-animation:hover {
  transform: scale(1.2) !important;
}
.selected-color {
  width: 45px;
  height: 30px;
  border-radius: 3px;
}
</style>
