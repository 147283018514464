
import { Vue, Options } from 'vue-class-component'
import { Prop, PropSync } from 'vue-property-decorator'

@Options({
  emits: ['update:selectedVal'],
  name: 'ColorPicker',
})
export default class ColorPicker extends Vue {
  @Prop({ type: Array }) readonly colorArray!: string[]
  @Prop({ type: String }) readonly placement = 'top-start'
  @Prop({ type: String }) readonly width?: string = '320px'
  @PropSync('selectedVal', { type: String }) _selectedVal!: string
  isShowPopover = false

  selectColor(color: string) {
    this._selectedVal = color
    this.isShowPopover = false
  }
}
